//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { useStoryblokGlobalComponents } from '@odyssey/realtainment';
import { useGlobalUI, GlobalUIElements } from '@/composables/useGlobalUI';
import useUiState from '@/composables/useUiState';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'SidebarMenu',
  components: {
    SButton: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    SMenuItem: () => import(/* webpackChunkName: "chunk-header" */ './SMenuItem'),
    XIcon: () => import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/x_icon.svg?inline'),
    SocialBar: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SocialBar')
  },
  directives: {
    ClickOutside
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup() {
    const { toggleGlobalUI, isGlobalUIActive, setGlobalUI } = useGlobalUI();
    const { socialLinks } = useUiState();
    const { globalComponents } = useStoryblokGlobalComponents();
    const headerData = computed(() => globalComponents.value.header);

    return {
      isActive: isGlobalUIActive(GlobalUIElements.SideNav),
      toggleSideNav: () => toggleGlobalUI(GlobalUIElements.SideNav),
      closeSideNav: () => setGlobalUI(GlobalUIElements.SideNav, false),
      onCTAClick: () => {
        setGlobalUI(GlobalUIElements.SideNav, false);
        setGlobalUI(GlobalUIElements.FiltersModal, true);
      },
      headerData,
      socialLinks
    };
  }
};
