//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SMenu',
  components: {
    SMenuItem: () => import(/* webpackChunkName: "chunk-header" */ './SMenuItem')
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
