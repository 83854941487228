//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, useRoute, onMounted, watch } from '@nuxtjs/composition-api';
import ClickOutside from 'vue-click-outside';
import { useGlobalUI } from '@/composables/useGlobalUI';
import { AB_TESTING } from '@/helpers';

const BASE_CLASS = 'n-menu-item';
export default {
  name: 'SMenuItem',
  inheritAttrs: false,
  directives: {
    ClickOutside
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => ['external', 'internal', 'dropdown', 'category-select'].includes(val)
    },
    label: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    newTab: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    },
    cssFlags: {
      type: Array,
      default: () => []
    },
    children: {
      type: Array,
      default: () => []
    },
    visibleOn: {
      type: Array,
      default: () => ['xs', 'sm', 'md', 'lg']
    },
    testId: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit, root }) {
    const route = useRoute();
    const { toggleGlobalUI } = useGlobalUI();

    const getCurrentCity = computed(() => ({}));
    const dropdownChildren = ref(null);
    const isDropdownActive = ref(false);
    const isMobile = computed(() => root?.$screen.md === false);

    const localStorageCity = ref(null);
    const isMotivPage = computed(() => props.link?.includes('/motiv/'));

    const isItemHighlighted = ref(null);
    const updatedLink = ref(null);

    function highlightItem(link, url) {
      if (link === url) {
        isItemHighlighted.value = true;
      } else {
        isItemHighlighted.value = false;
      }
    }

    onMounted(() => {
      const url = window?.location?.pathname + window?.location?.search;

      highlightItem(updatedLink.value, url);

      if (!window || !window?.localStorage) return;

      localStorageCity.value = computed(() =>
        localStorage.getItem('CITY_MODAL_SELECTED') === AB_TESTING ? null : localStorage.getItem('CITY_MODAL_SELECTED')
      );
    });

    watch(route, () => {
      if (isMotivPage.value && localStorage?.getItem('CITY_MODAL_SELECTED')) {
        localStorageCity.value = computed(() =>
          localStorage.getItem('CITY_MODAL_SELECTED') === AB_TESTING
            ? null
            : localStorage.getItem('CITY_MODAL_SELECTED')
        );
        getLink(localStorageCity.value);
      }

      const url = window?.location?.pathname + window?.location?.search;
      highlightItem(updatedLink.value, url);
    });

    function getLink(link) {
      const isTechniquenDerMarlerei = computed(() =>
        props.link?.includes('techniken-der-malerei-abstrakte-komposition-malen')
      );
      if (isMotivPage.value && localStorageCity.value?.value && !isTechniquenDerMarlerei.value) {
        updatedLink.value = props.link?.replace('motiv', localStorageCity.value?.value);
      } else {
        updatedLink.value = link;
      }
      return updatedLink.value;
    }

    const isCurrent = computed(() => {
      const isExternal = props.type === 'external';
      const isDropdown = props.type === 'dropdown';
      const isCategorySelect = props.type === 'category-select';

      if (isExternal && !props.link) return false;
      if (isDropdown) {
        const matchingChild = props.children
          .filter(({ type }) => type === 'internal')
          .find(({ link }) => link === route.value.path);

        return matchingChild !== undefined;
      }
      if (isCategorySelect) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const selectedCategory = getCurrentCity.value?.url_key;
        const isCurrentCityRoute = route.value.params?.slug === selectedCategory;
        return selectedCategory && isCurrentCityRoute;
      }
      const internalRoute = props.link;

      return internalRoute === route.value.fullPath;
    });

    const containerClasses = computed(() => [
      BASE_CLASS,
      ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`),
      isDropdownActive.value ? 'n-menu-item--dropdown-active' : '',
      isCurrent.value ? 'n-menu-item--current' : '',
      'n-menu-item--visible-on',
      ...props.visibleOn.map((f) => `n-menu-item--visible-on--${f}`)
    ]);

    const isHighlighted = computed(() => route.value.path === '/' && props.highlight);
    const isCategorySelected = computed(() => Object.keys(getCurrentCity.value || {}).length > 0);
    const categoryRoute = computed(() =>
      isCategorySelected.value ? { name: 'category', params: { slug: getCurrentCity.value?.url_key } } : null
    );
    const categoryLabel = computed(() => props.label.replace('{{city}}', getCurrentCity.value?.name));

    function setDropdownVisible(isActive) {
      if (props.type !== 'dropdown') return;
      const nextState = isActive === undefined ? !isDropdownActive.value : isActive;
      isDropdownActive.value = nextState;
    }

    function onClick() {
      emit('navigating');
    }

    const noop = () => {};

    // Microcart listeners are different on desktop from mobile
    const containerListeners = computed(() => ({
      mouseleave: isMobile.value ? noop : () => setDropdownVisible(false)
    }));

    return {
      containerClasses,
      dropdownChildren,
      setDropdownVisible,
      toggleGlobalUI,
      isDropdownActive,
      categoryRoute,
      categoryLabel,
      onClick,
      isCurrent,
      containerListeners,
      isHighlighted,
      localStorageCity,
      getLink,
      isItemHighlighted,
      updatedLink
    };
  }
};
