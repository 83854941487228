import { render, staticRenderFns } from "./SMenuItem.vue?vue&type=template&id=468ac058&scoped=true"
import script from "./SMenuItem.vue?vue&type=script&lang=js"
export * from "./SMenuItem.vue?vue&type=script&lang=js"
import style0 from "./SMenuItem.vue?vue&type=style&index=0&id=468ac058&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468ac058",
  null
  
)

export default component.exports