//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, useContext, watch } from '@nuxtjs/composition-api';
import { useCart, formatGtm } from '@odyssey/realtainment';
import useUiState from '~/composables/useUiState';

export default {
  components: {
    ProductSummary: () => import(/* webpackChunkName: "chunk-page-product" */ '@/components/Product/ProductSummary'),
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner')
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    },
    transition: {
      type: String,
      required: true
    }
  },
  setup() {
    const {
      $gtm,
      app: { $screen }
    } = useContext();
    const { cart, loading } = useCart();
    const { toggleCartSidebar, isCartSidebarOpen } = useUiState();

    const platformTotals = ref({});
    const productsInCart = computed(() => cart.value?.items || []);
    const totals = computed(() => {});

    const trackContinueShopping = () => {
      $gtm.push(formatGtm('all/CONTINUE_SHOPPING', {}));
    };

    const trackProceedToCheckout = () => {
      $gtm.push(formatGtm('all/PROCEED_TO_CHECKOUT', {}));
    };

    watch(isCartSidebarOpen, () => {
      // adjust height of minicart if cookie banner is visible
      if (isCartSidebarOpen.value && process.client && !$screen.md) {
        const cookieBanner = document.querySelector('.t-consentPrompt');
        const microcart = document.querySelector('.microcart--wrapper');

        if (cookieBanner && microcart) {
          microcart.style.height = `calc(100% - ${cookieBanner.clientHeight}px)`;
        }
      }
    });

    return {
      loading,
      productsInCart,
      totals,
      subtotal: computed(() => cart.value?.subtotal || 0),
      platformTotals,
      isCartSidebarOpen,
      toggleCartSidebar,
      trackContinueShopping,
      trackProceedToCheckout
    };
  }
};
