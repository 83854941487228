//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref } from '@nuxtjs/composition-api';

export default {
  name: 'SHeaderSlogan',
  props: {
    preText: {
      type: [String, Object],
      required: false
    },
    highlightedText: {
      type: [String, Object],
      required: false
    },
    afterText: {
      type: [String, Object],
      required: false
    }
  },
  setup() {
    const highlightWidthLength = ref(null);

    const cssVars = computed(() => {
      const vars = {
        '--highlight-width': `${highlightWidthLength.value?.offsetWidth + 40}px`
      };
      return vars;
    });

    return {
      cssVars,
      highlightWidthLength
    };
  }
};
