//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  computed,
  reactive,
  toRefs,
  useRouter,
  useRoute,
  useContext,
  onMounted,
  onBeforeUnmount
} from '@nuxtjs/composition-api';
import useUiState from '@/composables/useUiState';
import { useStoryblokGlobalComponents, useCart, formatGtm, cartGetters } from '@odyssey/realtainment';
import { useGlobalUI, GlobalUIElements } from '@/composables/useGlobalUI';
import debounce from 'lodash/debounce';
export default {
  name: 'SHeader',
  components: {
    SMenu: () => import(/* webpackChunkName: "chunk-header" */ '@/components/Header/SMenu'),
    Microcart: () => import(/* webpackChunkName: "chunk-header" */ '@/components/Header/Microcart'),
    SidebarMenu: () => import(/* webpackChunkName: "chunk-header" */ '@/components/Header/SidebarMenu'),
    BurgerIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon-burger-menu.svg?inline'),
    CartIcon: () => import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon-cart.svg?inline')
  },
  props: {
    theme: {
      type: String,
      default: 'dark'
    },
    reduced: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const router = useRouter();
    const route = useRoute();
    const { toggleGlobalUI } = useGlobalUI();
    const { cart } = useCart();
    const { openCartSidebar, closeCartSidebar, isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const {
      $gtm,
      app: { $screen }
    } = useContext();
    const state = reactive({
      wrapper: null,
      anchor: null,
      isSidebarActive: false,
      isSticky: false,
      isHidden: false,
      prevScrollPos: 0
    });

    const { globalComponents } = useStoryblokGlobalComponents();

    const noop = () => {};

    const isMobile = computed(() => root.$screen.breakpoint === 'sm');

    const isProductPage = computed(
      () => route.value.name.includes('product-event') || route.value.name.includes('theme__')
    );

    const headerData = computed(() => {
      if (globalComponents.value.header && Object.keys(globalComponents.value.header).length > 0) {
        return globalComponents.value.header;
      }
      return null;
    });

    const menuItems = computed(() => headerData.value?.navigation?.items || []);

    const totalQuantity = computed(() => cartGetters.getTotalItems(cart.value));

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      state.isHidden =
        currentScrollPos > state.prevScrollPos &&
        currentScrollPos > state.wrapper.offsetTop + state.wrapper.offsetHeight;

      state.prevScrollPos = currentScrollPos;
    };
    const debouncedHandleScroll = debounce(handleScroll, 25);

    onMounted(() => {
      if (process.server) return;
      window.addEventListener('scroll', debouncedHandleScroll);
      state.prevScrollPos = window.pageYOffset;

      const ob = new IntersectionObserver((entries) => {
        state.isSticky = !entries[0].isIntersecting;
      });
      ob.observe(state.anchor);
    });

    onBeforeUnmount(() => {
      if (process.server) return;
      window.removeEventListener('scroll', debouncedHandleScroll);
    });

    function gotoCheckout() {
      if (cart.value?.items?.length > 0 && router.currentRoute.name !== 'checkout') {
        $gtm.push(formatGtm('all/PROCEED_TO_CHECKOUT', {}));
        router.push({ path: '/checkout/' });
      }
    }

    const microcartWrapperListeners = computed(() => ({
      mouseover: isMobile.value || isCartSidebarOpen.value ? noop : openCartSidebar,
      mouseleave: isMobile.value ? noop : closeCartSidebar
    }));

    const microcartListeners = computed(() => ({
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      click: isMobile.value ? toggleCartSidebar : noop
    }));

    const containerClasses = computed(() => {
      return {
        'app-header--hidden': state.isHidden,
        'app-header--scrolling': state.isSticky
      };
    });

    const isCheckoutRedirect = computed(
      () => $screen.md || (!$screen.md && totalQuantity.value > 0 && !isProductPage.value)
    );
    // on mobile when elements are in cart we want to redirect direclty to checkout so we need to force close the sidebar
    // as it will trigger overflow on default.vue (based on the uistore)
    function updateCartSidebar() {
      if (isCheckoutRedirect.value) {
        closeCartSidebar();
      }
    }
    return {
      ...toRefs(state),
      containerClasses,
      totalQuantity,
      cartItems: computed(() => cart.value?.items),
      isMobile,
      microcartListeners,
      microcartWrapperListeners,
      menuItems,
      headerData,
      gotoCheckout,
      toggleSideNav: () => toggleGlobalUI(GlobalUIElements.SideNav, false),
      isHomepage: computed(() => route.value.path === '/'),
      route,
      screen: $screen,
      isProductPage,
      updateCartSidebar
    };
  }
};
